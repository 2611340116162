import { Typography, TypographyProps } from "@material-ui/core"
import { GeelyCarModelResponse, SavingCalculator } from "api"
import BottomButtons from "common/BottomButtons"
import BottomText from "common/BottomText"
import { Desktop, Mobile } from "common/MediaQueries"
import NextImage from "common/NextImage"
import { formatCurrency } from "common/utils/formatters"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import Card, { CardData } from "./Card"
import SliderComponent from "./SliderComponent"

const PURCHASE_MODEL_ID = process.env.PURCHASE_MODEL_ID

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
  padding-bottom: 30px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding-bottom: 30px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    height: 100%;
  }
`
const StyledImageContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 40%;
    height: 100%;
    overflow: visible;
    margin-left: 10%;
  }
  @media (min-width: 1450px) {
    width: 50%;
    margin-left: 0;
  }
`
const StyledCarBgImage = styled.div<{ $inView?: boolean }>`
  width: 100%;
  object-fit: cover;
  object-position: left;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
  }
  /* ${(p) => p.theme.breakpoints.down("sm")} {
    ${(p) => (!p.$inView ? `transform: scale(1.3)` : ` transform: scale(1)`)};
  } */
`
const StyledMobileImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 2/1;
`

const CAR_HEIGHT = 619
const CAR_WIDTH = 905

const StyledCarImage = styled.div<{ $inView?: boolean }>`
  position: absolute;
  width: ${CAR_WIDTH}px;
  height: ${CAR_HEIGHT}px;
  top: calc(50% - ${CAR_HEIGHT / 2}px);
  left: -80px;
  transition: all 0.3s ease-in-out;
  ${(p) =>
    !p.$inView ? `transform: translateX(-100px)` : ` transform: translateX(0)`};
`

const StyledCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.breakpoints.down("xs")} {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 50%;
  }
`
const StyledFlexContainer = styled.div`
  flex-grow: 1;
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`
const StyledTitle = styled(Typography)<TypographyProps & { component: string }>`
  text-align: center;
  font-size: 26px;
  margin-top: 12px;
  font-weight: 300;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: 40px;
    font-size: 38px;
    font-weight: normal;
    @media only screen and (max-height: 750px) {
      margin-top: 20px;
    }
  }
`
const StyledTotalSavings = styled.div`
  background-color: ${(p) => p.theme.colors.paleGreyThree};
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    order: 3;
    width: 500px;
    margin-top: 30px;
    margin-bottom: 50px;
    @media only screen and (max-height: 750px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`
const StyledTotalSavingsText = styled(Typography)`
  font-size: 20px;
  white-space: nowrap;
  span {
    font-weight: bold;
  }
`
const StyledCardContainer = styled.ul`
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    width: 500px;
    justify-content: space-between;
    gap: 20px;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`
const StyledBottomWrap = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: -30px;
    margin-bottom: -15px;
  }
`
const StyledBottomTextContainer = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-left: 20px;
    margin-right: 20px;
  }
`
const SavingsCalculator = ({
  data,
  isHomePage,
}: {
  data: GeelyCarModelResponse
  isHomePage?: boolean
}) => {
  const [activeStep, setActiveStep] = useState<SavingCalculator>(
    data.savingCalcPerDistanceComponent[0]
  )
  const savingsData = data.savingCalcTitles
  const { ref, inView } = useInView({
    threshold: 0.3,
  })

  const oldCar = PURCHASE_MODEL_ID === "26"

  if (!activeStep) {
    return null
  }
  const savingsCards: CardData[] = [
    {
      icon: "/icon-electrical.png",
      title: "עלות חשמל",
      geelyCostKey: "energyCostGeely",
      comparisonText: "לעומת רכב בנזין",
      comparisonCostKey: "gasCost",
    },
    {
      icon: "/icon-maintenance.png",
      title: "עלות אחזקה",
      geelyCostKey: "maintenanceCostGeely",
      comparisonText: "לעומת רכב בנזין",
      comparisonCostKey: "maintenanceCostCompatitor",
    },

    {
      icon: "/icon-license-fee.png",
      title: "אגרת רישוי",
      geelyCostKey: "licenseFeeGeely",
      comparisonText: "לעומת רכב בנזין",
      comparisonCostKey: "licenseFeeGasCost",
    },
  ]

  return (
    <StyledMainContainer ref={ref}>
      <StyledImageContainer>
        <Mobile>
          <StyledMobileImageWrapper>
            <NextImage
              image={
                oldCar
                  ? "/saving_image.jpg"
                  : "/saving_image_calculator_mobile_NEW.jpg"
              }
              alt="מחשבון חיסכון דלק"
              priority
            />
          </StyledMobileImageWrapper>
        </Mobile>
        <Desktop>
          <StyledCarBgImage>
            <NextImage
              image="/background_car.jpg"
              alt={savingsData.mainTitle}
              cover
              priority={!isHomePage}
            />
          </StyledCarBgImage>

          <StyledCarImage $inView={inView}>
            <NextImage
              image={
                oldCar ? "/only_car_2.png" : "/only_car_2_calculator_NEW.png"
              }
              alt="מחשבון חיסכון דלק"
              priority={!isHomePage}
            />
          </StyledCarImage>
        </Desktop>
      </StyledImageContainer>
      <StyledCalculatorContainer>
        <StyledFlexContainer>
          <StyledTitle component={isHomePage ? "h2" : "h1"} variant="h1">
            {savingsData.mainTitle}
          </StyledTitle>
          <SliderComponent
            data={data.savingCalcPerDistanceComponent}
            onChange={(value) => {
              const step = data.savingCalcPerDistanceComponent.find(
                (step) => step.yearlyKM === value
              )
              if (step) {
                setActiveStep(step)
              }
            }}
          />

          <Desktop>
            <StyledCardContainer
              aria-label={`רשימה של ${savingsCards.length} סוגי חיסכון`}
            >
              {savingsCards.map((card, index) => (
                <Card key={index} activeStep={activeStep} card={card} />
              ))}
            </StyledCardContainer>
          </Desktop>

          <StyledTotalSavings>
            <StyledTotalSavingsText id="totalSaving">
              *חיסכון שנתי ₪
              <span>{formatCurrency(activeStep.totalSavingSum, 0)}</span>
            </StyledTotalSavingsText>
          </StyledTotalSavings>
          <Mobile>
            <BottomButtons refer={true} />
          </Mobile>

          <Mobile>
            <StyledCardContainer>
              {savingsCards
                .map((card, index) => (
                  <Card key={index} activeStep={activeStep} card={card} />
                ))
                .reverse()}
            </StyledCardContainer>
          </Mobile>
        </StyledFlexContainer>

        <Desktop>
          <StyledBottomWrap>
            <BottomButtons refer={true} />
          </StyledBottomWrap>
        </Desktop>

        <StyledBottomTextContainer>
          <BottomText
            align="center"
            textAlign="center"
            inputText={data.calculatorLegalTerms}
          />
        </StyledBottomTextContainer>
      </StyledCalculatorContainer>
    </StyledMainContainer>
  )
}

export default SavingsCalculator
