import { Slider, Typography } from "@material-ui/core"
import { SavingCalculator } from "api"
import { formatCurrency } from "common/utils/formatters"
import styled from "styled-components"

const TinyText = styled(Typography)`
  font-size: 16px;
  font-weight: normal;
  color: #6c737a;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 18px;
  }
`

const StyledWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 500px;
    max-width: 500px;
    @media only screen and (max-height: 750px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
`
const StyledSlider = styled(Slider)`
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 500px;
  }

  .MuiSlider-markLabel {
    margin-top: 20px;
  }
  .MuiSlider-markLabelActive {
    margin-top: 20px;
  }
  .MuiSlider-thumb {
    width: 30px;
    height: 30px;
  }
  .MuiSlider-valueLabel {
    > span {
      background-color: ${(p) => p.theme.colors.white};
      box-shadow: 0 5px 25px 0 ${(p) => p.theme.colors.cloudyBlue};
      color: ${(p) => p.theme.colors.midnight};
      width: 40px;
      height: 40px;
      transform: none;
      width: 110px;
      border-radius: 15px;
      margin-right: -15px;

      :after {
        content: "";
        position: absolute;
        bottom: 15px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: ${(p) => p.theme.colors.white};
        border-bottom: 0;
        margin-bottom: -20px;
        margin-left: 3px;
      }
      > span {
        color: ${(p) => p.theme.colors.midnight};
        text-align: center;
        font-size: 16px;
        transform: none;
      }
    }
  }
`

const setLabel = (value: number) => `${formatCurrency(value)} ק״מ`

type SliderComponentProps = {
  data: SavingCalculator[]
  onChange: (val: number) => void
}

const SliderComponent = ({ data, onChange }: SliderComponentProps) => {
  if (!data || !data.length) {
    return null
  }
  const first = data[0].yearlyKM
  const last = data[data.length - 1].yearlyKM
  const step = data[1].yearlyKM - first
  return (
    <StyledWrapper>
      <StyledSlider
        id="sliderComponent"
        getAriaValueText={setLabel}
        defaultValue={Math.floor(last / 2)}
        valueLabelFormat={setLabel}
        step={step}
        min={first}
        max={last}
        ThumbComponent="button"
        valueLabelDisplay="on"
        onChange={(_, value) => {
          if (Array.isArray(value)) {
            onChange(value[0])
          } else {
            onChange(value)
          }
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TinyText>{formatCurrency(first, 0)} ק״מ</TinyText>
        <TinyText>{formatCurrency(last, 0)} ק״מ</TinyText>
      </div>
    </StyledWrapper>
  )
}

export default SliderComponent
