import { Typography } from "@material-ui/core"
import { SavingCalculator } from "api"
import { Desktop } from "common/MediaQueries"
import { formatCurrency } from "common/utils/formatters"
import Image from "next/image"
import styled from "styled-components"

const StyledCardContainer = styled.li`
  display: flex;
  flex-direction: row;
  border: solid 1px ${(p) => p.theme.colors.silver};
  width: 100%;
  flex-grow: 1;
  margin: 14px 0;
  align-items: center;
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: column;
    width: 150px;
    height: 210px;
    justify-content: center;
    padding: 0px 10px 10px 10px;

    @media only screen and (max-height: 750px) {
      height: 200px;
    }
  }
`

const StyledGeneralContainer = styled.div`
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.down("sm")} {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    text-align: center;
    position: relative;
    padding: 22px 20px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    position: relative;
    top: -30px !important;
  }
`
const StyledIcon = styled.div`
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 76px;
    height: 79px;
    z-index: 2;
    margin-right: -20px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 102px;
    height: 106px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    margin-top: -40px;
    @media only screen and (max-height: 750px) {
      width: 92px;
      height: 96px;
    }
  }
  display: flex;
  justify-content: center;
`
const StyledCostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.down("sm")} {
    text-align: right;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-left: 0;
    text-align: center;
    width: auto;
  }
`
const StyledComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.up("md")} {
    text-align: center;
    width: auto;
  }
`

const StyledCostText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 6px;
    font-weight: normal;
  }
`
const StyledCost = styled(Typography)`
  font-size: 22px;
  font-weight: 500;

  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 6px;
  }
`
const StyledComparisonText = styled(Typography)`
  font-size: 14px;

  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 6px;
  }
`
const StyledComparisonCost = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
`
const StyledHorizontalSeparator = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  border-top: 1px solid ${(p) => p.theme.colors.paleGrey};
`

export type CardData = {
  icon: string
  title: string
  geelyCostKey: string
  comparisonText: string
  comparisonCostKey: string
}

type CardProps = {
  card: CardData
  activeStep: SavingCalculator
}

const Card = ({ card, activeStep }: CardProps) => {
  return (
    <StyledCardContainer>
      <StyledIcon>
        <Image
          width="76"
          height="79"
          src={card.icon?.trim()}
          alt={card.title}
        />
      </StyledIcon>
      <StyledGeneralContainer>
        <StyledCostContainer>
          <StyledCostText>{card.title}</StyledCostText>
          <StyledCost id={card.title}>
            ₪<span>{formatCurrency(activeStep[card.geelyCostKey], 0)}</span>
          </StyledCost>
        </StyledCostContainer>
        <Desktop>
          <div style={{ alignItems: "center" }}>
            <StyledHorizontalSeparator />
          </div>
        </Desktop>
        <StyledComparisonContainer>
          <StyledComparisonText>{card.comparisonText}</StyledComparisonText>
          <StyledComparisonCost>
            ₪
            <span>{formatCurrency(activeStep[card.comparisonCostKey], 0)}</span>
          </StyledComparisonCost>
        </StyledComparisonContainer>
      </StyledGeneralContainer>
    </StyledCardContainer>
  )
}

export default Card
